body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "PT Sans",
     "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", 
     "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.message {
  font-weight: normal;
  background-color: darkred;
  color: white;
  padding: 15px;
}

.wish {
  font-size: 24px;
  font-weight: bold;
}